import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";

// Components
import { Address } from "../components/layout/address";
import { Mailchimp } from "../components/forms/mailchimp";

const Page = styled.div`
  padding: 20px;

  & .left-column {
    min-height: calc(${props => props.height}px - 40px);

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      max-height: calc(${props => props.height}px - 20px);
    }
  }

  & .right-column {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    max-height: calc(${props => props.height}px - 20px - 74px);

    @media (max-width: 900px) {
      max-height: calc(${props => props.height}px - 20px);
    }

    & svg {
      width: auto;
      height: auto;
      max-height: 100%;
      max-width: 600px;

      margin: auto 0 0 auto;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 400px auto;
  grid-gap: 20px;

  @media (max-width: 1020px) {
    grid-template-columns: 290px 1fr;
  }

  @media (max-width: 767px) {
    display: block;
    grid-template-columns: unset;
    grid-gap: unset;
  }
`;

const ExhibitionsContainer = styled.div`
  align-self: flex-start;
  width: 100%;

  // margin: 1em 0;

  // @media (max-width: 900px) {
  //   margin: 40px 0;
  // }

  // & h1 {
  //   margin: 0 0 1em 0;
  // }

  & ol {
    margin: 1em 0;
  }

  & .back-button {
    & a {
      transition: 150ms color ease;

      &:hover {
        color: #6f6d6d;
      }
    }
  }

  & .single-exhibition {
    margin: 0 0 1em 0;

    & p {
      margin: 0;

      & .active-exhibition {
        opacity: 0;
        visibility: hidden;
        transition: 250ms opacity ease;
      }
    }

    & a.active {
      & p {
        & .active-exhibition {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &:hover {
      & .active-exhibition {
        opacity: 1;
        visibility: visible;
      }
    }

    & a {
      transition: 150ms color ease;

      &:hover {
        color: #6f6d6d;
      }
    }
  }
`;

const ExhibitionYear = ({ data, pageContext }) => {
  const height = use100vh();

  const exhibitions = data.allPrismicExhibition.edges
    .filter(exhibition => exhibition.node.data.year === pageContext.year)
    .map((exhibition, index) => (
      <li key={exhibition.node.id} className="single-exhibition">
        <Link to={exhibition.node.url} activeClassName="active">
          <p>{exhibition.node.data.artist.text}</p>
          <p>{exhibition.node.data.title.text}</p>
          <p>
            {exhibition.node.data.start_date}
            {" – "}
            {exhibition.node.data.end_date}
            <span className="active-exhibition"> ←</span>
          </p>
        </Link>
      </li>
    ));

  return (
    <Page height={height}>
      <Grid>
        <div className="left-column">
          <ExhibitionsContainer>
            <Address />

            <h1>{pageContext.year}</h1>

            <ol>
              {exhibitions}

              <li className="back-button">
                <Link to={`/exhibitions/`}>Back ←</Link>
              </li>
            </ol>
          </ExhibitionsContainer>
          <Mailchimp />
        </div>
      </Grid>
    </Page>
  );
};

export const query = graphql`
  {
    allPrismicExhibition(sort: { fields: data___start_date, order: DESC }) {
      edges {
        node {
          id
          url
          data {
            artist {
              text
            }
            title {
              text
            }
            year: start_date(formatString: "YYYY")
            start_date(formatString: "DD MMMM YYYY")
            end_date(formatString: "DD MMMM YYYY")
          }
        }
      }
    }
  }
`;

export default ExhibitionYear;
